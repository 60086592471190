<template lang="pug">
div(v-if='Servicios').negro
    //-PC
    v-container(fluid).d-none.d-md-flex.negro
    v-container(fluid align='center' justify='center').d-none.d-md-flex.negro
        v-row(align='center' justify='center').mt-12.ml-2.negro  
            h4(v-html='Servicios.Construcción.beneficios.título_1').font-weight-light.white--text.pr-0.mr-2.pl-9
            h4(v-html='Servicios.Construcción.beneficios.título_2').font-weight-bold.white--text.pr-0
    v-container.d-none.d-md-flex.negro.mt-3
        v-row(align='center' justify='center').negro
            v-col(cols=3 v-for='{texto, imagen}, i in Servicios.Construcción.beneficios.elementos' :key='i' align='center' justify='center')
                v-card.negro(width=140).mr-5.ml-5
                    v-card-text(align='center' justify='center')
                        v-img(:src='imagen')
                    v-card-actions
                        v-row(align='center' justify='center')
                            h4(v-html='texto' style='line-height: 12px; text-align: center').white--text.font-weight-light
    v-container(fluid).d-none.d-md-flex.negro
    v-container(fluid).d-none.d-md-flex.negro
    v-container(fluid).d-none.d-md-flex.negro
    v-container(fluid).d-none.d-md-flex.negro
    //-Móvil
    v-container(fluid).d-md-none.negro
    v-container(fluid align='center' justify='center').d-md-none.negro
        v-row(align='center' justify='center').mt-12.negro  
            h5(v-html='Servicios.Construcción.beneficios.título_1' ).font-weight-light.white--text.pr-0
        v-row(align='center' justify='center')
            h5(v-html='Servicios.Construcción.beneficios.título_2' ).font-weight-light.white--text.pr-0.mt-3
    v-container(fluid).negro.mt-5.d-md-none
        v-row(align='center' justify='center').negro
            v-carousel(v-model='valor' cycle :interval='5000' height='40vh' :show-arrows='false').d-md-none
                v-carousel-item(v-for='{texto, imagen}, i in Servicios.Construcción.beneficios.elementos' :key='i')
                    v-container
                        v-row(align='center' justify='center')
                            v-img(:src='imagen' max-width=105 max-height=170)
                        v-row(align='center' justify='center').mt-9
                            h4(v-html='texto' style='line-height: 10px; text-align: center').white--text.font-weight-light
    v-container(fluid).negro.mt-8.d-md-none
</template>
<script>
export default {
  data: ()=>({
    valor: 0,
  }),
}
</script>